<template>
    <popup-dialog-base
        ref="FilterDialog"
        title="Filter"
        :toolButtons="buttons"
    >

        <template v-slot:button="{dialog}">
            <v-btn
                @click="dialog.visible = true"
                icon
            >
                <v-icon>
                    mdi-filter
                </v-icon>
                <v-badge
                    v-if="getActiveFilters().length > 0"
                    color="error"
                    :content="getActiveFilters().length"
                    >
                </v-badge>
            </v-btn>
        </template>

        <template v-slot:content>
            <v-dialog
                overlay-opacity="0"
                v-model="showFilterSelection"
            >   
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="filterSearch" autofocus ref="filterSearch" hide-details></v-text-field>
                    </v-card-title>
                    <v-card-text>

                        <v-list
                            class="overflow-y-auto"
                            dense
                        >   
                            <v-list-item
                                v-for="(item, index) in filteredItems"
                                :key="index"
                                @click="selectFilter(item)"
                            >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-row
                no-gutters
                class="ma-2"
            >
                <v-col cols="12"
                    v-for="(item, index) in localValue" :key="index"
                >

                    <v-chip
                        exact
                        label
                        class="mt-2 pa-0 pl-2 pr-1"
                    >   
                        <strong
                        >
                            {{item.text}}
                        </strong>
                        <v-select
                            v-model="item.operator"
                            full-width
                            hide-details
                            dense
                            class="value-selector pl-2  grey lighten-2"
                            :items="getOperator(item)"
                            @input="selectOption"
                        />
                        <v-select 
                            v-model="item.selected" 
                            hide-details
                            dense
                            class="value-input value-selector grey lighten-3 pl-2" 
                            full-width 
                            v-if="item.options"
                            :items="item.options"
                            @input="selectOption"
                        />
                        <v-text-field
                            v-else
                            v-model="item.selected"
                            hide-details
                            dense
                            :type="item.type"
                            @input="selectOption"
                        />

                        
                        <v-btn
                            class="pa-0 ma-0"
                            x-small
                            icon
                            @click="remove(item)"
                        >
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        
                    </v-chip>   
                </v-col>
            </v-row>
        </template>

        

    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue';

export default {
    components:{
        PopupDialogBase
    },
    created(){
        let filter = this.$cookie.get("filter_"+this.collection);
        if(filter && JSON.parse(filter).length > 0){
            this.localValue = JSON.parse(filter);
            this.changeValue(this.localValue);
        }
    },
    props:{
        value:Array,
        items:Array,
        label:String,
        collection:String
    },
    data(){
        return{
            showFilterSelection:false,
            filterSearch:'',
            buttons:[
                {label:"Neuer Filter", color:"primary", icon:"mdi-plus", method:this.openFilterBox, viewCondition:()=>{return true}},
                {label:"Filter löschen", color:"error", icon:"mdi-close", method:this.clearFilter, viewCondition:()=>{return true}},
            ],
            localValue: this.value,
            selectedOption:undefined,
            showFilter:false,
            showBox:false
        }
    },
    watch:{
        value(value){
            this.localValue = value || [];
        }
    },

    computed:{
        filteredItems(){
            var items = []
            for(let filter of this.items){
                let search = this.filterSearch.toLocaleLowerCase();
                let filterName = filter.key.toLocaleLowerCase();
                if( filterName.indexOf(search) !== -1){
                    items.push(filter)
                }
            }
            return items;
        }
    },
    methods:{
        getOperator(item){

            
            let operator = [
                {text:'=',value:'_eq'},
                {text:'!=',value:'_neq'},
            ]
            if(item.type === 'dateTime' || item.type === 'integer' || item.type === 'date'){
                operator.push({text:'>',value:'_gt'})
                operator.push({text:'<',value:'_lt'})
                operator.push({text:'>=',value:'_gte'})
                operator.push({text:'<=',value:'_lte'})
            }
            
            return operator
        },
        selectFilter(filter){
            this.localValue.push(filter);
            this.showFilterSelection = false;
        },
        clearFilter(){
            this.localValue = []; 
            this.changeValue([]);
        },
        openFilterBox(){
            this.filterSearch = ""
            this.showFilterSelection = true;
            this.$nextTick(()=>{
                this.$nextTick(()=>{
                    this.$refs.filterSearch.focus();
                })
            })
        },
        selectOption(){
            this.$emit("input", this.localValue)
            this.$cookie.set("filter_"+this.collection, JSON.stringify(this.getActiveFilters()));
        },
        changeValue(event){
            this.$emit("input", event);
            this.$cookie.set("filter_"+this.collection, JSON.stringify(this.getActiveFilters()));
        },
        remove (item) {
            this.localValue.splice(this.localValue.indexOf(item), 1);
            this.localValue = [...this.localValue];
            this.$emit("input", this.localValue);
            this.$cookie.set("filter_"+this.collection, JSON.stringify(this.getActiveFilters()));
        },
        getActiveFilters(){
            var filters = []
            for(let filter of this.localValue){
                if (filter.selected){
                    filters.push(filter)
                }
            }
            return filters
        }
    }
    
}
</script>

<style>
.value-selector .v-select__selections input { display: none;}
.value-selector{
    /* width:100px !important; */
    font-size:12px !important;
}
.value-input .v-select__selections{
    min-width:150px;
}
</style>