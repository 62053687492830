<template>
    <v-container
        v-if="fields"
        flex
        class="ma-0 pa-0 full-width"
    >   
        <v-expand-transition>
            <div
                v-show="peopleManager.expandedRow === -1"
            >

                <v-card class=" mr-4 ml-4">

                    <v-row class="mb-3" >
                        <v-col 
                            class="pa-1 ma-0"
                        >
                            <v-text-field
                                v-model="peopleManager.peopleSearch"
                                prepend-inner-icon="mdi-magnify"
                                label="Suche"
                                single-line
                                hide-details
                                flat
                                solo
                                dense
                                clearable
                                class=""
                            ></v-text-field>
                        </v-col>
                        <v-col
                            class="pa-1 pr-8 text-end"
                            cols="auto"
                        >
                            <!-- :items="['Bezahlt','Bezahlt abweichend', 'Nicht bezahlt', 'Warteliste', 'Abgemeldet', 'Absage', 'Angemeldet', 'Bestätigt', 'E1','E2','E3','Mädchen','Junge']" -->
                            <interface-filter-combobox
                                v-model="peopleManager.peopleFilter"
                                :items="peopleManager.getFilterPresets()"
                                :collection="peopleManager.collectionWithYear"
                                label="Filter"
                                @input="updateFilter()"
                            />
                        </v-col>
                    </v-row>
                </v-card>
                
                <v-lazy>
                    <v-data-table
                        v-if="!accessForbidden"
                        ref="DataTable"
                        hide-default-header
                        :headers="getTableHeaders()"
                        v-model="peopleManager.peopleSelectedRows"
                        :items="peopleData"
                        mobile-breakpoint="0"
                        loading-text="Daten werden geladen."
                        :loading="peopleManager.loadingData"
                        dense
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :custom-sort="customSort"
                        :options="pagination"
                        :footer-props="footerProps"
                        class="mr-0 ml-0 pl-0 pr-0"
                        @pagination="(newPagination)=>{pagination=newPagination}"
                        @toggle-select-all="selectDeselect"
                    >   
                    <template v-slot:header="{ props }">
                        <!-- {{props.headers}} -->
                        <thead>
                            <tr>
                                <th>
                                    <v-checkbox 
                                        class="mt-0 pt-0" 
                                        color="grey"
                                        :value="props.everyItem"
                                        :indeterminate="props.someItems && !props.everyItem" 
                                        @click="selectDeselect"
                                        hide-details 
                                    />
                                </th>
                                <th 
                                    class="pa-0"
                                    :style='{
                                        "position":head.value==="action"?"sticky":"relative",
                                        "right":"0px",
                                    }'
                                    @click="setSortBy(head)" 
                                    v-for="(head, index) in props.headers" :key="index"
                                    :class="'text-'+head.align"
                                >
                                    <div
                                        :class="head.value==='action'?'tools':''"
                                    >
                                        <span style="position:absolute; left:0px;" v-if="sortBy.includes(head.value)">
                                            <v-icon v-if="sortDesc" size="16">mdi-arrow-down</v-icon>
                                            <v-icon v-else size="16">mdi-arrow-up</v-icon>
                                        </span>
                                        <a class="text--secondary"
                                            :class="head.value==='action'?'mr-2':''"
                                        >
                                            {{head.text}}
                                        </a>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </template>

                        <template v-slot:item="props">
                            <tr
                                :class="{
                                    'striped':props.index%2===0 || props.index===0,
                                    'blue lighten-5':props.index === peopleManager.expandedRow,
                                    'blue lighten-4':props.isSelected,
                                }" 
                            >
                                
                                <td class="ma-0 pr-0 pl-4"><v-checkbox class="ma-0 mt-2 mb-2" hide-details :input-value="props.isSelected" @change="props.select($event)"/></td>
                                
                                <td class="ma-0 pa-0 pl-1 pr-1" v-for="(prop, key) in props.item" :key="key" :class="{'d-none':key==='id'}">
                                    
                                    <!-- {{prop}} -->
                                    <v-col class="ma-0 pa-0" v-if="prop.meta && prop.meta.interface === 'tags'">
                                        <v-chip class="mr-1 mb-1 pa-2" small label v-for="(tag, index) of prop.value" :key="index">
                                            <b>{{tag}}</b>
                                        </v-chip>
                                    </v-col>
                                    <div
                                        v-else-if="prop.display==='status-multiple'"
                                    >
                                        <v-tooltip bottom>
                                            <!-- {{prop}} -->
                                            <span><strong>{{$utils.beautifyKey(prop.meta.field)}}</strong></span> <br/>
                                            <span>{{prop.value}}</span>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" :class="getStatusColor(prop.value, prop.meta.display_options.status)+'--text'">{{getStatusIcon(prop.value, prop.meta.display_options.status)}}</v-icon>
                                            </template>
                                        </v-tooltip>
    
                                    </div>
                                    <div
                                        v-else-if="prop.meta && prop.meta.interface==='select-multiple-checkbox'"
                                    >
    
                                        <v-chip-group>
                                            <v-chip class="secondary pa-1" small label
                                                v-for="(position, index) in getMultipleChoiceValue(prop.value, prop.meta.options.choices)" :key="index"
                                            >
                                                <strong class="text--secondary">{{position}}</strong>
                                            </v-chip>
                                        </v-chip-group>
                                    </div>
                                    <div v-else-if="prop.display==='status-button'">
                                        <v-btn small v-if="prop.meta.display_options.function_name" @click="callFunction(props.item.id, prop.meta.display_options.function_name)" icon>
                                            <v-icon v-if="prop.value" class="success--text">{{prop.meta.display_options.icon_true}}</v-icon>
                                            <v-icon v-else class="secondary--text">{{prop.meta.display_options.icon_false}}</v-icon>
                                        </v-btn>
                                        <div v-else>
                                            <v-icon v-if="prop.value" class="success--text">{{prop.meta.display_options.icon_true}}</v-icon>
                                            <v-icon v-else class="secondary--text">{{prop.meta.display_options.icon_false}}</v-icon>
                                        </div>
                                    </div>
                                    <div v-else-if="prop.meta && prop.meta.interface === 'datetime'">
                                        <v-chip class="secondary pa-1" v-if="prop.value" small label>
                                            <v-icon class="mr-1" size="20">mdi-calendar</v-icon>
                                            <strong class="text--secondary">{{formatedDatetime(prop.value)}}</strong>
                                        </v-chip>
                                    </div>
                                    <div v-else-if="prop.meta && prop.meta.interface === 'boolean'">
                                        <v-checkbox class="mt-1 mb-2" hide-details disabled v-model="prop.value"></v-checkbox>
                                    </div>
                                    <div
                                        v-else-if="prop.value !== undefined"
                                    >
                                        {{prop.value}}
                                    </div>
                                    <div
                                        v-else
                                    >
                                        {{prop}}
                                    </div>
                                </td>
                                
                                <td v-if="getFieldByInterface('edit-buttons') === undefined">
                                </td>
                                <td 
                                    v-else
                                    
                                    class="fixed-col text-right" style="min-width:120px; width:100%; position:sticky; right:0px;"
                                >
                                    <div
                                        v-if="getFieldByInterface('edit-buttons').meta.options"
                                        style="position:absolute; right:0px; top:0px; padding-bottom:6px; padding-top:6px; padding-left:2px;"
                                        :style='{
                                            "background-color":props.index%2?"#ffffff":"#fafafa"
                                            }'
                                        :class='{
                                            "blue lighten-4":props.isSelected
                                        }'
                                    >
                                        <v-btn
                                            v-for="(action, index) in getFieldByInterface('edit-buttons').meta.options.actions" :key="index"
                                            
                                            x-small
                                            fab
                                            class="elevation-2 mr-2"
                                            color="primary"
                                            :disabled="handleDisabling(action, props)"
                                            @click="executeFunction(action, props)"
                                        >
                                            <v-icon>
                                                {{action.icon}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-lazy>

                <confirm-dialog 
                    ref="delDialog"
                    icon="mdi-alert"
                    icon-color="error--text"
                    title="Personendaten wirklich löschen?"
                    text="Personendaten werden unwiderruflich gelöscht."
                    :max-width="400"
                    :onConfirm="deletePerson"
                />

                <confirm-dialog 
                    ref="resendMailDialog"
                    icon="mdi-email"
                    icon-color="primary--text"
                    title="Bestätigungs-Email senden?"
                    text="Die Bestätigungs-Email wird neu versendet."
                    :max-width="400"
                    :onConfirm="sendConfirmationLink"
                />
            </div>
        </v-expand-transition>

        <v-snackbar color="success" v-model="emailSend"><v-icon>mdi-check-bold</v-icon> Email wurde versand</v-snackbar>

    </v-container>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog.vue';
import InterfaceFilterCombobox from './interfaces/InterfaceFilterCombobox.vue';
import dayjs from 'dayjs';

export default {
    name:"PeopleTable",
    components:{
        ConfirmDialog,
        InterfaceFilterCombobox
    },
    async mounted(){
        let result = await this.$network.requestGet("/fields/"+this.collection);
        this.fields = result.data.data;

        let personId = this.$router.currentRoute.query.id;
        if(personId){
            let result = await this.$network.requestGet("/items/"+this.collection+"/"+personId);
            if(result.status === 200){
                this.peopleManager.expandedRow = 1;
                this.peopleManager.editedId = personId;
                this.peopleManager.editDataSet(personId);
                this.peopleManager.formEditorTitle = result.data.data.vorname + " " + result.data.data.nachname
            }
        }
    },
    props:{
        collection:{type:String, default:"mitarbeiter"},
        accessForbidden:{type:Boolean, default:false},
        peopleManager:Object,
        dashboard:Object,
    },
    data(){
        return{
            footerProps:{
                'items-per-page-options': [10, 20, 50, 100,-1]
            },
            pagination:{
                page: 0,
                itemsPerPage: 10,
                rowsPerPage:[10,20,30,50,-1],
                pageStart: 0,
                pageStop: -1,
                sortBy:[],
                sortDesc:[false]
            },
            peopleTable:this,
            sortBy:[],
            sortDesc:false,
            customTableLabels:{
                "Status":"",
                "Bezahlt":"",
                "Email":"",
                "Sonderurlaub":"",
                "Extra":"",
                "Führungszeugnis":"",
            },
            checkAll:false,
            deleteId:undefined,
            rowId:undefined,
            fields:undefined,
            emailSend:false,
            formEditorTitle:'',
            headerReplace:{
                "status_email_bestaetigt":"Email",
                "status_bestaetigt":"Status"
            }
        }
    },
    computed:{
        peopleData(){
            let search = this.peopleManager.peopleSearch;
            if(this.peopleManager.tablePreset && this.peopleManager.peopleData){
                let data = [];
                for(let item of this.peopleManager.peopleData){
                    var tableItem = undefined;
                    let searchFilterPassed = false;
                    for(let field of this.peopleManager.tablePreset.layout_query.tabular.fields){
                        if(item[field] !== undefined){
                            if (tableItem === undefined){
                                tableItem = {id:item.id};
                            }
                            tableItem[field] = {
                                value:item[field],
                                display:this.getFieldDisplay(field),
                                meta:this.getFieldMeta(field)
                            };
                            if (search === null || search === "" || (typeof item[field] === 'string' && search.toLowerCase().includes(item[field].toLowerCase()) || typeof item[field] === 'string' && item[field].toLowerCase().includes(search.toLowerCase()))){
                                searchFilterPassed = true;
                            }
                        }
                    }
                    if (tableItem && searchFilterPassed){
                        data.push(tableItem);
                    }
                }
                return data;
            }
            return [];
        }
    },
    methods:{
        setSortBy(headerItem){

            if(headerItem.value != "action"){
                if(this.sortBy.includes(headerItem.value)){
                    this.sortDesc = !this.sortDesc
                    if(!this.sortDesc){
                        this.sortBy = [];
                    }
                }else{
                    this.sortBy = [headerItem.value];
                }
            }
            
        },
        selectDeselect(){
            let table = this.$refs.DataTable;
            var visiblePageItems = this.peopleData.slice(table.options.pageStart, table.options.pageStop)
            if(table.everyItem){
                this.peopleManager.peopleSelectedRows = [];

            }else if(!table.everyItem){
                this.peopleManager.peopleSelectedRows = visiblePageItems;
            }else if(table.someItems){
                this.peopleManager.peopleSelectedRows = [];
            }
        },
        getMultipleChoiceValue(value, choices){
            let returnValues = [];
            if(value){
                for(let item of value){
                    for(let choice of choices){
                        if (choice.text == item){
                            returnValues.push(choice.value);
                        }
                    }
                }
            }
            return returnValues;
        },
        formatedDatetime(datetime){
            if(datetime){
                return dayjs(datetime).format("DD.MM.YY - HH:mm");
            }
            return "";
        },
        updateFilter(){
            this.peopleManager.loadPeopleData(true);
        },
        getStatusIcon(statusName, statusData){
            for(let status of statusData){
                if (status.name === statusName){
                    return status.icon;
                }
            }
            return "";
        },
        getStatusColor(statusName, statusData){
            for(let status of statusData){
                if (status.name === statusName){
                    return status.color;
                }
            }
            return "";
        },
        customSort(items, index, isDescending) {
            if(this.sortBy.length > 0){
                items.sort((a, b) => {
                    if (isDescending[0]) {
                        if(b[this.sortBy[0]].value > a[this.sortBy[0]].value){
                            return -1;
                        }else{
                            return 1;
                        }
                    } else {
                        if(b[this.sortBy[0]].value > a[this.sortBy[0]].value){
                            return 1;
                        }else{
                            return -1;
                        }
                    }
              });
            }
          return items;
        },
        callFunction(id, functionName){
            this[functionName](id);
        },
        sendConfirmationLinkDialog(id){
            this.rowId = id;
            this.$refs.resendMailDialog.show()
        },
        async sendConfirmationLink(){
            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.dashboard.activeCampYear.toString()+"&fields=*,freizeit.*")
            let camp;
            if(result.status === 200){
                camp = result.data.data[0];
            }
            result = await this.$network.requestGet("/items/"+this.collection+"/"+this.rowId);
            if (result.status === 200){
                let data = result.data.data;
                let type = "";
                if(this.collection.includes("mitarbeiter")){
                    type = "mitarbeiter"
                }else if(this.collection.includes("teilnehmer")){
                    type = "teilnehmer"
                }
                let payload = {
                    type:type, 
                    collection:this.collection, 
                    data:data,
                    camp:camp
                }
                await this.$network.requestPost("/send-confirmation-link", payload);
                this.emailSend = true;
            }
            this.rowId = undefined;
        },
        removeFilter(item){
            this.peopleManager.peopleFilter.splice(this.peopleManager.peopleFilter.indexOf(item), 1)
            this.peopleManager.peopleFilter = [...this.peopleManager.peopleFilter]
        },
        getFieldMeta(key){
            for(let field of this.fields){
                if(field.field === key){
                    return field.meta;
                }
            }
            return undefined;
        },
        getFieldByInterface(key){
            for(let field of this.fields){
                if (field.meta && field.meta.interface === key){
                    return field;
                }
            }
            return undefined;
        },
        getFieldDisplay(key){
            for(let field of this.fields){
                if(field.field === key){
                    if(field.meta){
                        if(field.meta.display){
                            return field.meta.display;
                        }
                    }
                }
            }
        },
        getLanguage(){
            var language = navigator.language || navigator.userLanguage
            if(["de","de-DE"].includes(language)){
                return "de-DE"
            }
            return language;
        },
        getTranslation(key){
            if(this.headerReplace[key]){
                return this.headerReplace[key];
            }
            for(let field of this.peopleManager.fieldData){
                if (field.field === key){
                    if(field.meta.translations !== null){
                        for(let translation of field.meta.translations){
                            if(translation.language === this.getLanguage()){
                                return translation.translation;
                            }
                        }
                    }
                }
            }
            return this.pascalize(key);
        },
        pascalize(str) {
            str = str.replaceAll("_"," ");
            return str.replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
        },
        getTableHeaders(){
            var headers = [];
            if(this.peopleManager.tablePreset && this.peopleManager.peopleData){
                // headers.push({text:"ID",value:"id"})

                let item = this.peopleManager.peopleData[0];
                if (item != undefined){
                    for(let field of this.peopleManager.tablePreset.layout_query.tabular.fields){
                        if(item[field] !== undefined){
                            let fieldTranslation = this.getTranslation(field);
                            if(this.customTableLabels[fieldTranslation] !== undefined){
                                fieldTranslation = this.customTableLabels[fieldTranslation];
                            }

                            headers.push({text:fieldTranslation, value:field});
                            // headers.push({text:field, value:field, sortable:true});
                        }
                    }
                }
                headers.push({text:"Aktionen", value:"action", align:"end"});
            }
            return headers;
        },
        editPerson(props){
            this.$router.push({path:this.$router.currentRoute.path, query:{id:props.item.id, jahr:this.dashboard.activeCampYear}})
            this.peopleManager.loadPeopleForm = true;
            this.peopleManager.expandedRow=props.index
            this.peopleManager.editedId = props.item.id;
            this.peopleManager.editDataSet(props.item.id);
            this.peopleManager.formEditorTitle = this.peopleManager.editData.vorname + " " +this.peopleManager.editData.nachname;
            
            setTimeout(()=>{
                this.peopleManager.loadPeopleForm = false;
            },200);
        },
        deletePerson(){
            this.peopleManager.deleteDataSet(this.deleteId);
        },
        openConfirmationDialog(props){
            this.peopleManager.openEmployeeConfirmation(props.item.id)
        },
        openParticipantConfirmationDialog(props){
            this.peopleManager.openParticipantConfirmation(props.item.id)
        },
        executeFunction(action, props){
            eval("this."+action.function_name)(props);
        },
        handleDisabling(action, props){
            let prop = undefined;
            for(let p of this.peopleData){
                if(p.id === props.item.id){
                    prop = p[action.disabled_by_property]
                }
            }
            if(prop){
                return !prop.value
            }
            return false;
        }
    }

}
</script>

<style>
    .tools{
        width:85px;
        padding-right:0px;
        margin-right:0px;
        background-color:#ffffff;
        float:right;
    }
    .tools-striped{
        background-color: #fafafa;
    }
    .tools-striped-odd{
        background-color: #ffffff;
    }

    .full-width{
        min-width:100%;
    }
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }

    .selected{
        background-color:  rgba(197, 234, 255, 0.322);
    }

    .row-dark{
        background-color:rgb(241, 241, 241);
    }
    th.text-start.sortable{
        padding:0px!important;
    }
    
    /* th.text-start.sortable{
      max-width: 50px;
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    } */
</style>