import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/lib/locale/de'
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
    theme: {
        themes: {
            light: {
            primary: '#4065c9',
            'primary-dark': '#505363',
            warning: '#f5b133',
            success: '#17bf97',
            secondary: '#b0b8c5',
            error: '#d93062',
            background:'#fff'
            },
        },
    },
  });
