<template>
    <report-card-base
        title="Kosten"
        icon="mdi-currency-eur"
    >
        <template v-slot:content>

            <v-simple-table v-if="tableData">
                <thead>
                    <tr>
                        <th></th>
                        <th v-for="col in tableHeader" :key="col">{{col}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(freizeit, freizeitName) in tableData" :key="freizeitName">
                        <td>{{ freizeitName }}</td>
                        <td v-for="(value, index) in freizeit" :key="index">
                            <strong v-if="freizeitName ==='Summe'">{{ value }} €</strong>
                            <span v-else> {{ value }} €</span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>

    </report-card-base>
</template>

<script>
import ReportCardBase from './ReportCardBase.vue';
export default {
    components:{
        ReportCardBase
    },
    data(){
        return {
            freizeiten:undefined,
            data:undefined,
            tableHeader:[ "Freizeiten", "T-Shirts", "Tassen", "Summe"],
            tableData:undefined,
        }
    },
    props:{
        year:String
    },
    async created(){
        await this.getIncome();
        this.tableData = this.extractTableData(this.data);

    }, 
    methods:{
        async getIncome(){
            let url = "/items/teilnehmer_"+this.year+"?limit=-1";
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}}]}'
            url+="&fields=vorname,nachname,freizeit,tshirt,tassen";
            let personResult = await this.$network.requestGet(url);
            this.data = personResult.data;

            url = "/items/camp";
            url += `?filter={"_and":[{"jahr":{"_eq":"${this.year.toString()}"}}]}`;
            url += "&fields=freizeit.*,tshirt_preis,tasse_preis"
            let campResult = await this.$network.requestGet(url)
            this.freizeiten = campResult.data.data[0];
        },
        extractTableData(data){
            let table = {};
            for(let person of data.data){
                if(table[person.freizeit] === undefined){
                    table[person.freizeit] = [0,0,0,0];
                }
                table[person.freizeit][0] += this.getFreizeitAmount(person);
                table[person.freizeit][1] += this.getShirtAmount(person);
                table[person.freizeit][2] += this.getCupAmount(person);
                table[person.freizeit][3] += this.getFreizeitAmount(person) + this.getShirtAmount(person) + this.getCupAmount(person);
            } 
            let sum = [0,0,0,0];
            for(let key in table){
                sum[0] += table[key][0];
                sum[1] += table[key][1];
                sum[2] += table[key][2];
                sum[3] += table[key][3];
            }
            table["Summe"] = sum;
            return table;
        },
        getFreizeitByName(name){
            for(let freizeit of this.freizeiten.freizeit){
                if(freizeit.name === name){
                    return freizeit;
                }
            }
            return undefined;
        },
        getShirtAmount(person){
            if(person.tshirt === true){
                return this.freizeiten.tshirt_preis;
            }
            return 0
        },
        getFreizeitAmount(person){
            return this.getFreizeitByName(person.freizeit).preis;
        },
        getCupAmount(person){
            return this.freizeiten.tasse_preis * person.tassen;
        }
    }
}
</script>