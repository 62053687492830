<template>
    <v-container 
        v-if="camp"
        class="pa-0"
    >
        <v-alert
            text
            v-if="!registrationIsActive() && (hasAdminAccess() || hasAppAccess())"
            border="left"
            type="warning"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Anmeldung ist noch nicht aktiviert</h3>
                    <p>
                        Du siehst die Anmeldung weil du als Benuzter angemeldet bist.
                    </p>
                </v-col>
            </v-row>
        </v-alert>

        <count-down
            v-if="camp[this.type+'_anmeldung_countdown'] && !this.countdownPassed"
            :due-date-string="camp[this.type+'_anmeldung_countdown']"
            v-model="countdownPassed"
        />
        <people-form-editor
            ref="formEditor"
            v-if="(editData && !registrationSend && !registrationError) && (countdownPassed || hasAdminAccess() || hasAppAccess())"
            v-model="editData"
            :peopleManager="peopleManager"
            :backendForm="false"
            :dataValidation="dataValidation"
            :year="year"
        >
            <template v-slot:header-suffix></template>
        </people-form-editor>
        <v-alert
            text
            v-if="registrationSend"
            border="left"
            type="success"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Die Anmeldung ist fast abgeschlossen. </h3>
                    <p>
                        Du erhälst in kürze eine Emailbestätigung. Bitte klicke den Link um die Anmeldung abzuschließen.
                    </p>
                    <p>
                        Sollte die Email nicht direkt angekommen sein, prüfe dein <b>Spam-Postfach</b>. In seltenen Fällen kann es auch schon mal <b>mehrere Stunden</b> dauern bis die Email ankommt.
                    </p>
                    <p>
                        <b>Eine erneute Anmeldung für die selbe Person ist ab diesem Zeitpunkt nicht mehr notwendig.</b>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="grey lighten-3 grey--text text--darken-4" @click="newRegistration()">
                        <v-icon>mdi-form-select</v-icon> Neue Anmeldung
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        
        <v-alert
            text
            v-if="registrationError"
            border="left"
            type="error"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Bei der Anmeldung ist etwas schief gelaufen. </h3>
                    <p>
                        Leider ist bei der Anmeldung etwas schief gelaufen. Bitte versuche es noch einmal.
                        Der Administrator wurde informiert.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="grey lighten-3 grey--text text--darken-4" @click="newRegistration()">
                        <v-icon>mdi-form-select</v-icon> Erneut anmelden
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-alert
            text
            v-if="!editData && siteLoaded && !showLogin || (!countdownPassed && !hasAdminAccess() && !hasAppAccess())"
            border="left"
            type="info"
            class="ma-2"
            color="primary"
        >
            <v-row>
                <v-col>
                    <h3>Anmeldung nicht aktiv</h3>
                    <p>
                        Du musst dich noch etwas gedulden. Die Anmeldung ist noch nicht aktiviert.
                    </p>
                    <v-btn  @click="showLogin=true" class="primary">
                        Zum Login
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <login 
            v-if="showLogin"
            :isComponent="false" 
            :redirect="false" 
            @loggedIn="onUserLogin"
        />
    </v-container>

</template>

<script>

import PeopleFormEditor from "./PeopleFormEditor.vue"
import CountDown from "./CountDown.vue";
import Login from "../pages/Login.vue";

export default {
    components:{
        PeopleFormEditor,
        CountDown,
        Login
    },
    inject:[
        "app"
    ],
    async mounted(){
        this.year = parseInt(this.app.$router.currentRoute.path.replace(/[^0-9]/g,''));
        let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString());
        if(result.status === 200){
            this.camp = result.data.data[0];
            this.dataValidation = this.camp.eingabe_evaluierung

            if(this.isMitarbeiterRegistration()){
                this.collection = this.camp.mitarbeiter_collection_key;
                this.type = "mitarbeiter"
            }else if(this.isTeilnehmerRegistration()){
                this.type = "teilnehmer"
                this.collection = this.camp.teilnehmer_collection_key;
            }

            if(!this.camp[this.type+'_anmeldung_countdown']){
                this.countdownPassed = true;
            }
            
            if(this.registrationIsActive() || this.hasAdminAccess() || this.hasAppAccess()){
                this.editData = await this.createEmptyDataSet();
            }
        }
        this.siteLoaded = true;
    },
    data(){
        return{
            TEILNEHMER_EXTRA:"Teilnehmer Anmeldung Extra",
            TEILNEHMER:"Teilnehmer Anmeldung",
            MITARBEITER:"Mitarbeiter Anmeldung",
            countdownPassed:undefined,
            fieldsLoaded:false,
            peopleManager:this,
            fieldData:[],
            editData:undefined,
            editedId:-1,
            collection:"",
            waitingForServerResponse:false,
            registrationSend:false,
            registrationError:false,
            type:"",
            siteLoaded:false,
            dataValidation:undefined,
            camp:undefined,
            year:undefined,
            showLogin:false,
            writePermissionFields:["*"],
        }
    },
    computed:{
        collectionWithYear(){
            return this.collection;
        }
    },
    methods:{
        async onUserLogin(){
            if(this.registrationIsActive() || this.hasAdminAccess() || this.hasAppAccess()){
                this.editData = await this.createEmptyDataSet();
            }
            this.showLogin = false;
        },
        hasAdminAccess(){
            return this.app.userData && this.app.userData.role && (this.app.userData.role.admin_access || this.app.userData.role.name === "Verwaltung") ;
        },
        hasAppAccess(){
            return this.app.userData && this.app.userData.role && this.app.userData.role.app_access;
        },
        registrationIsActive(){
            return this.isMitarbeiterRegistration() && this.camp.mitarbeiter_anmeldung_aktiv ||
            (this.isTeilnehmerRegistration() && this.camp.teilnehmer_anmeldung_aktiv)
        },
        isMitarbeiterRegistration(){
            return this.app.$router.currentRoute.name.includes(this.MITARBEITER);
        },
        isTeilnehmerRegistration(){
            let teilnehmerRegistration = this.app.$router.currentRoute.name.includes(this.TEILNEHMER);
            let teilnehmerExtraRegistration = this.app.$router.currentRoute.name.includes(this.TEILNEHMER_EXTRA);
            return teilnehmerExtraRegistration || teilnehmerRegistration;
        },
        isExtraRegistration(){
            return this.app.$router.currentRoute.name.includes(this.TEILNEHMER_EXTRA);
        },
        async createEmptyDataSet(){
            this.fieldData = await this.app.$network.getFieldsData(this.collection, true);
            let editData = {};
            for(let field of this.fieldData){
                if(!field.meta.hidden){
                    if (field.schema){
                        editData[field.field] = field.schema.default_value;
                    }else{
                        editData[field.field] = undefined;
                    }
                }
            }
            return editData;
        },
        cancelEdit(){},
        async newRegistration(){
            this.registrationSend = false;
            this.registrationError = false;
            this.$refs.formEditor.$refs.form.resetValidation();

        },
        getSonderUrlaubStatus(){
            if(this.editData.sonderurlaub){
                return "Gewünscht";
            }
            return "Nicht gewünscht";
        },
        async saveEdit(){
            this.editData["registration_summary"] = {
                registration_summary: this.$refs.formEditor.$refs.registrationSummary.getRegistrationSummary(),
                checkout_table: this.$refs.formEditor.$refs.checkoutDisplay.getCheckoutData(),
            }
            this.editData["status_extra_anmeldung"] = this.isExtraRegistration();
            this.editData["status_sonderurlaub"] = this.getSonderUrlaubStatus();
            let payload = {
                type: this.type,
                collection: this.collection,
                data: this.editData,
                // checkoutTable: this.$refs.formEditor.$refs.checkoutDisplay.getCheckoutData(),
                // registrationSummary: this.$refs.formEditor.$refs.registrationSummary.getRegistrationSummary(), 
            }
            this.waitingForServerResponse = true;
            let result = await this.$network.requestPost("/register", payload);
            this.waitingForServerResponse = false;
            if (result.status === 200){
                this.registrationSend = true;
                this.editData = await this.createEmptyDataSet();
            }else{
                this.registrationError = true;
            }
        },
    }
}
</script>